import React, { FormEvent, useEffect, useState } from "react"
import { Accordion, Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import ExamplePunk from "../../../images/ExamplePunk_1.png"
import PunkBanner from "../../../images/BONUS_NOOBPUNKS.png"
import { isMobile } from "../../../utils/device"
import MetaMaskConnectButton from "../../buttons/MetaMaskConnectButton";

const styles = require("./claim-punks.module.scss")
const claimTreeJson = require("./json/claim-tree.json");

type ClaimPunksProps = {
  accountAddress: string | null,
  contract: Contract,
  punksContract: Contract,
  getAndSetAccount: Function,
}

const PUNKS_SUPPLY_LIMIT: number = 10000;

/**
 * Claim punks page.
 */
const ClaimPunks: React.FC<ClaimPunksProps> = ({ accountAddress, contract, punksContract, getAndSetAccount }) => {
  // General state
  const [isLoading, setLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Punks contract state
  const [currentSupply, setCurrentSupply] = useState<number>(0);
  const [claimPrice, setClaimPrice] = useState<number>(0);
  const [isClaimingActive, setIsClaimingActive] = useState<boolean>(false);
  const [numClaimMinted, setNumClaimMinted] = useState<number>(0);
  const [claimLimit, setClaimLimit] = useState<number>(0);
  const [minTreeEntry, setMinTreeEntry] = useState<number>(10000);

  // Form state
  const [numPunksToBuy, setNumPunksToBuy] = useState<string>("10");

  // Function to actually do the minting
  const mintPunks = () => {
    if (isClaimingActive) {
      return punksContract.methods.claimPricePerNft().call().then((price: number) => {
        const numPunksBuying: number = parseInt(numPunksToBuy)
        const proofIndex: number = parseInt("" + numClaimMinted) + numPunksBuying - 1
        const entry = claimTreeJson.filter(obj => obj.address === accountAddress && obj.index === proofIndex)
        if (!!entry && entry.length === 1) {
          return punksContract.methods.claimPunks(entry[0].proof, numPunksBuying).send(
            {
              from: accountAddress,
              value: price * numPunksBuying,
            })
            .on('transactionHash', (hash: string) => {
              setTransactionUrl("https://etherscan.io/tx/" + hash);
          })
        } else {
          setLoading(false);
        }
      })
    } else {
      setLoading(false);
    }
  }

  // Punks contract state
  useEffect(() => {
    if (isLoading) {
      // TODO (Ben): Change
      mintPunks().then(() => {
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        setShouldShowError(true);
      });
    }
    if (punksContract) {
      punksContract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply);
      })

      punksContract.methods.claimPricePerNft().call().then((price: number) => {
        setClaimPrice(price / 1000000000000000000);
      })

      punksContract.methods.isClaimingActive().call().then((isClaimingActive: boolean) => {
        setIsClaimingActive(isClaimingActive);
      })
    }
  }, [punksContract, isLoading]);

  useEffect(() => {
    if (!!accountAddress && !!punksContract) {
      punksContract.methods.getNextClaimIndex(accountAddress).call().then((numMinted: number) => {
        setNumClaimMinted(numMinted);
      })
    }
  }, [accountAddress, punksContract]);

  useEffect(() => {
    if (!!accountAddress && isClaimingActive && claimTreeJson && claimTreeJson.length > 0) {
      const entries = claimTreeJson.filter(obj => obj.address === accountAddress)
      if (entries.length > 0) {
        const maxEntry = entries.reduce((a, b) => a.index > b.index ? a : b);
        const minEntry = entries.reduce((a, b) => a.index < b.index ? a : b);
        if (!!maxEntry) {
          setClaimLimit(maxEntry.index + 1);
          setMinTreeEntry(minEntry.index + 1);
        }
      }
    }
  }, [accountAddress, isClaimingActive]);

  useEffect(() => {
    if (!!accountAddress && isClaimingActive) {
      setNumPunksToBuy(String(Math.min(claimLimit - numClaimMinted, 50)));
    }
  }, [accountAddress, isClaimingActive, claimLimit, numClaimMinted, minTreeEntry]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  }

  const hasSaleStarted = isClaimingActive;
  const remainingPunks = String(PUNKS_SUPPLY_LIMIT - currentSupply);
  const isSoldOut = ((PUNKS_SUPPLY_LIMIT === currentSupply) && hasSaleStarted);

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled
  const mintLimit =
    isClaimingActive
      ? Math.min(claimLimit - numClaimMinted, 50)
      : 0
  const currentPrice = claimPrice
  const mintBooleanCheck = numClaimMinted >= minTreeEntry ? mintLimit <= 0 : mintLimit <= 9

  return (
    <>
      <Container id="punks-intro" className="d-flex align-items-center justify-content-center">
        <Row>
          <Col className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="d-flex align-items-center justify-content-center">
                <Image src={PunkBanner} />
              </Col>
            </Row>

            <Row>
              <Col className="d-flex flex-column align-items-center justify-content-center">
                <p
                  className={styles.timerContextText}
                  style={accountAddress ? { fontWeight: "bold" } : { fontWeight: "bold", marginBottom: "0.5rem" }}
                >
                  BONUS CLAIM AVAILABLE
                </p>
                {!accountAddress && (
                  <p className={styles.timerContextText} >Connect Wallet to check for available claims</p>
                )}
              </Col>
            </Row>

            {(!isMobileAndMetamaskNotInstalled && !!accountAddress && !hasSaleStarted) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col className="d-flex align-items-center justify-content-center">
                  <div className={styles.comingSoonButton}>
                    CLAIMING SOON
                  </div>
                </Col>
              </Row>
            )}

            {(!isMobileAndMetamaskNotInstalled && !accountAddress && !isSoldOut) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col>
                  <div className="noob-metamask-connect-home-page">
                    <MetaMaskConnectButton getAndSetAccount={() => getAndSetAccount()} />
                  </div>
                </Col>
              </Row>
            )}

            {(!isMobileAndMetamaskNotInstalled && accountAddress && !isSoldOut && hasSaleStarted && mintBooleanCheck) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col className="d-flex align-items-center justify-content-center">
                  <div className={styles.comingSoonButton}>
                    NO ELIGIBLE CLAIMS
                  </div>
                </Col>
              </Row>
            )}

            {(!isMobileAndMetamaskNotInstalled && accountAddress && !isSoldOut && hasSaleStarted && (mintLimit > 9 || (numClaimMinted >= minTreeEntry && mintLimit > 0))) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Form className="d-flex align-items-center justify-content-center" noValidate onSubmit={onSubmit}>
                  <Col className="col-8 col-md-9">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={!hasSaleStarted}
                      className="noob-mint-button"
                    >
                      Mint
                    </Button>
                  </Col>
                  <Col className="col-4 col-md-3 d-flex align-items-center justify-content-center">
                    <Form.Control
                      as="select"
                      className="noob-mint-input"
                      onChange={({ target: { value } }) => {
                        setNumPunksToBuy(value);
                      }}
                      value={numPunksToBuy}
                      disabled={!hasSaleStarted}
                    >
                      {numClaimMinted >= minTreeEntry
                      ?
                      [...Array(mintLimit).keys()].map((index) => {
                        const number = index + 1
                        return <option key={number} value={number.toString()}>{number}</option>
                      })
                      :
                      [...Array(mintLimit - 9).keys()].map((index) => {
                        const number = index + 10
                        return <option key={number} value={number.toString()}>{number}</option>
                      })
                      }
                    </Form.Control>
                    <p
                      className="noob-max-mint-text"
                      onClick={() => {
                        if (hasSaleStarted) {
                          setNumPunksToBuy(mintLimit + "")
                        }
                      }}
                    >
                      MAX
                    </p>
                  </Col>
                </Form>
              </Row>
            )}

            {(!isMobileAndMetamaskNotInstalled && accountAddress && !!isClaimingActive && (mintLimit > 9 || (numClaimMinted >= minTreeEntry && mintLimit > 0))) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col className="d-flex flex-column align-items-center justify-content-center">
                  <p style={{ marginBottom: "0px" }}>CLAIMS REMAING: {claimLimit - numClaimMinted}</p>
                  <p>MINIMUM CLAIM AMOUNT: 10</p>
                </Col>
              </Row>
            )}

            {!isSoldOut && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  {/* <p className="mint-stats-text">{currentPrice} ETH | {remainingPunks} remaining</p> */}
                  <p className="mint-stats-text">
                    {!!punksContract && !!accountAddress
                      ? `${currentPrice} ETH | ${remainingPunks} remaining`
                      : `` // TODO (Ben): Fill in
                    }
                  </p>
                </Col>
              </Row>
            )}
            {(transactionUrl !== "") && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col className="d-flex align-items-center justify-content-center">
                  <a href={transactionUrl} target="_blank" className="primary-color-link pending-transaction-link" style={{ marginBottom: "1.45rem" }}>View last transaction</a>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <Image src={ExamplePunk} className="punks-intro-image" />
          </Col>
        </Row>
      </Container>

      <Container id="faq" className="noob-section" fluid>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>FAQ</h1>
            <Accordion flush>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className={styles.textQuestion}>First time buyer?</span></Accordion.Header>
                <Accordion.Body>
                  <p>The first step would be setting up your very own wallet using the <a href="https://metamask.io/" target="_blank" className="primary-color-link">metamask plugin</a> on your browser.</p>
                  <p>Once that is done, you're going to need some ETH. You can either buy this directly through metamask, or via an exchange (we recommend  <a href="https://www.gemini.com/share/vkz9wzh8" target="_blank" className="primary-color-link">Gemini</a> but <a href="https://www.coinbase.com/" target="_blank" className="primary-color-link">Coinbase</a> is another option as well).</p>
                  <p>With your ETH in hand, you are going to want to withdraw to your metamask address (if you purchased on an exchange). Then connect your wallet to our site using the option in the navigation bar.</p>
                  <p>Voilà! Just like that you are now ready to buy some NOOBPUNKS  - and may we say we are honored to have been your first foray into this wonderful world.</p>
                  <p>If you have any trouble please message the support channel in our Discord server and we will be more than happy to lend a hand.</p>
                  <span>Please note that our team will never contact you to request any personal or other identifying information. Be especially careful with your wallet and NEVER share your seed phrase with anyone.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header><span className={styles.textQuestion}>Can I purchase a NOOBPUNK on a mobile device?</span></Accordion.Header>
                <Accordion.Body>
                  <span>Only through the <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a> mobile browser. Otherwise, we only support purchasing on desktop devices via <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header><span className={styles.textQuestion}>Where can I learn more about the contract?</span></Accordion.Header>
                <Accordion.Body>
                  <span>You can view any of the transaction, code, etc. on <a href="https://etherscan.io/address/0xa5a1e6972ace6f4ae388fbafcb7ec12013b64f53" target="_blank" className="primary-color-link">Etherscan</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <br/>
    </>
  )
}

export default ClaimPunks
