import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import Web3 from "web3"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ClaimPunks from "../../components/pages/claimpunks"
import Noobs from '../../../abis/mainnet/Noobs.json'
import NoobPunks from '../../../abis/punks/mainnet/NoobPunks.json'
import DiscordButton from '../../components/buttons/DiscordButton'

const MAINNET_NETWORK_ID = 1;

const ClaimPunksPage: React.FC<PageProps> = pageProps => {
  // Pull web3 information - might be worth pulling this out so abstracted further from the page.
  // A HOC or putting it in Layout might be helpful.
  const [account, setAccount] = useState<string | null>(null)
  const [contract, setContract] = useState<any | null>(null)
  const [punksContract, setPunksContract] = useState<any | null>(null)
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  // web3
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")

  const getAndSetAccount = async () => {
    // Get customer account (wallet address) from meta mask
    const newAccounts = await web3.eth.getAccounts()
    const newAccount = newAccounts[0] as string
    setAccount(newAccount)
  }

  useEffect(() => {
    const setupWeb3Info = async () => {
      // Get contract from the network
      const networkId = await web3.eth.net.getId()
      if (networkId !== MAINNET_NETWORK_ID) {
        setShouldShowError(true);
      }

      const networkData = Noobs.networks[networkId]
      if (networkData) {
        // Set Noobs contract
        const abi = Noobs.abi
        const contractAddress = networkData.address
        const contract = new web3.eth.Contract(abi, contractAddress)
        setContract(contract)

        // Set Noobs Punks contract
        const noobPunksContract = new web3.eth.Contract(NoobPunks.abi, "0xa5a1e6972ace6f4ae388fbafcb7ec12013b64f53")
        setPunksContract(noobPunksContract)
      }

      getAndSetAccount();
    }
    // Update the document title using the browser API
    setupWeb3Info()
  }, []);

  return (
    <Layout pageProps={pageProps} accountAddress={account} contract={contract} getAndSetAccount={getAndSetAccount}>
      <SEO title="Punks - Claim" />
      <ClaimPunks contract={contract} punksContract={punksContract} accountAddress={account} getAndSetAccount={getAndSetAccount}/>
      <DiscordButton/>
      <Modal
          show={shouldShowError}
          onHide={() => setShouldShowError(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Network Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You're not connect to the Ethereum mainnet. Make sure you are connected to the Ethereum mainnet before minting!
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShouldShowError(false)}
              className="noob-dismiss-button"
            >
              Dismiss
            </Button>
          </Modal.Footer>
        </Modal>
    </Layout>
  )
}

export default ClaimPunksPage
